import React from "react";
import {
  Button,
  Modal,
  Form,
  Col,
  Row,
  InputGroup,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  getValueLive,
  getRegionByID,
  getRestaurantsOfUser,
  getZonesByPlaceID,
} from "../../api/Api";
import FontList from "../../styles/FontList";
import iconRestaurant from "../../image/iconrestaurants.png";

const listFont = FontList;

class InfoFluidity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      font_size: this.props.item.fontSize,
      font_size_canc: this.props.item.fontSize,
      font_family: this.props.item.fontFamily,
      font_family_canc: this.props.item.fontFamily,
      fluidityZone: this.props.item.fluidityZone,
      zindex_bg: 1,
      zindex_bg_canc: 1,

      font_familyHeader: this.props.item.fontFamilyHeader,
      font_familyHeader_canc: this.props.item.fontFamilyHeader,
      font_familyTitle: this.props.item.fontFamilyTitle,
      font_familyTitle_canc: this.props.item.fontFamilyTitle,
      font_sizeHeader: this.props.item.fontSizeHeader,
      font_sizeHeader_canc: this.props.item.fontFamilyHeader,
      font_sizeTitle: this.props.item.fontSizeTitle,
      font_sizeTitle_canc: this.props.item.fontSizeTitle,

      colorTitle: this.props.item.colorTitle,
      colorTitle_canc: this.props.item.colorTitle,
      colorHeader: this.props.item.colorHeader,
      colorHeader_canc: this.props.item.colorHeader,
      colorDescription: this.props.item.colorDescription,
      colorDescription_canc: this.props.item.colorDescription,

      restaurants: this.props.item.restaurants,
      restaurantSelected: this.props.item.restaurantSelected,
      zones: [],
      Curved_tb: this.props.item.borderRadius,
      Curved_tb_canc: this.props.item.borderRadius,
    };
    this.handleChangeFontSize = this.handleChangeFontSize.bind(this);
    this.handleChangeFontSizeTitle = this.handleChangeFontSizeTitle.bind(this);
    this.handleChangeFontSizeHeader = this.handleChangeFontSizeHeader.bind(
      this
    );

    this.handleChangeFontFamily = this.handleChangeFontFamily.bind(this);
    this.handleChangeFontFamilyTitle = this.handleChangeFontFamilyTitle.bind(
      this
    );

    this.handleChangeFontFamilyHeader = this.handleChangeFontFamilyHeader.bind(
      this
    );
    this.handleChangeIDZone = this.handleChangeIDZone.bind(this);
    this.handleChangeTextTitle_zone = this.handleChangeTextTitle_zone.bind(
      this
    );

    this.handleChangeColorText = this.handleChangeColorText.bind(this);
    this.handleChangeColorTextHeader = this.handleChangeColorTextHeader.bind(
      this
    );
    this.handleChangeColorTextTitle = this.handleChangeColorTextTitle.bind(
      this
    );
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
 
    this.handleChangeCurved = this.handleChangeCurved.bind(this);
 }
  componentDidMount() {
    this.state.fluidityZone.map((value) => this.getZoneLive(value.key));

    getRestaurantsOfUser()
      .then((res) => {
        this.setState({ restaurants: res });
      })
    /*.then(() => {
       if (
         Object.keys(this.state.restaurantSelected).length === 0 &&
         this.state.restaurantSelected.constructor === Object
       ) {
         this.setState({ restaurantSelected: this.state.restaurants[0] });
       }
     })
     .then(() => {
       getZonesByPlaceID(this.state.restaurantSelected).then((res) => {
         console.log(res);
         this.setState({ zones: res });
       });
     })
     .then(() => {
       console.log(this.state.restaurantSelected);
       console.log(this.state.zones);
     }); */
  }

  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };

  //Change Font Family Header
  handleChangeFontFamilyHeader(event) {
    this.setState({ font_familyHeader: event.target.value });
  }

  //Change Font Size Header
  handleChangeFontSizeHeader(event) {
    if (event.target.value > 40) {
      this.setState({ font_sizeHeader: 40 });
    } else if (event.target.value < 30) {
      this.setState({ font_sizeHeader: 30 });
    } else {
      this.setState({ font_sizeHeader: event.target.value });
    }
  }
  //Change Color  Header
  handleChangeColorTextHeader(event) {
    this.setState({ colorHeader: event.target.value });
  }

  //Change Font Size Title
  handleChangeFontSizeTitle(event) {
    if (event.target.value > 30) {
      this.setState({ font_sizeTitle: 30 });
    } else if (event.target.value < 20) {
      this.setState({ font_sizeTitle: 20 });
    } else {
      this.setState({ font_sizeTitle: event.target.value });
    }
  }
  //Change Font Family Title
  handleChangeFontFamilyTitle(event) {
    this.setState({ font_familyTitle: event.target.value });
  }
  //Change Color  Title
  handleChangeColorTextTitle(event) {
    this.setState({ colorTitle: event.target.value });
  }
  //Cahnge Font Size Description
  handleChangeFontSize(event) {
    if (event.target.value > 23) {
      this.setState({ font_size: 23 });
    } else if (event.target.value < 13) {
      this.setState({ font_size: 13 });
    } else {
      this.setState({ font_size: event.target.value });
    }
  }

  //Change Font Family Description
  handleChangeFontFamily(event) {
    this.setState({ font_family: event.target.value });
  }

  //Change Color  Description
  handleChangeColorText(event) {
    this.setState({ colorDescription: event.target.value });
  }
      //Change Curved
  handleChangeCurved(event) {
        this.setState({ Curved_tb: event.target.value });

  }
  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex: 1 });
    } else {
      this.setState({ zindex: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ zindex: this.state.zindex_canc });
    this.setState({ font_size: this.state.font_size_canc });
    this.setState({ font_family: this.state.font_family_canc });
    this.setState({ colorDescription: this.state.colorDescription_canc });

    this.setState({ font_sizeTitle: this.state.font_sizeTitle_canc });
    this.setState({ font_familyTitle: this.state.font_familyTitle_canc });
    this.setState({ colorTitle: this.state.colorTitle_canc });

    this.setState({ font_sizeHeader: this.state.font_sizeHeader_canc });
    this.setState({ font_familyHeader: this.state.font_familyHeader_canc });
    this.setState({ colorHeader: this.state.colorHeader_canc });
    // this.props.onLayerChange(this.props.item.i, this.state.zindex_canc);
    this.setState({ Curved_tb: this.state.Curved_tb_canc });
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ font_size_canc: this.state.font_size });
    this.setState({ font_family_canc: this.state.font_family });
    this.setState({ zindex_canc: this.state.zindex });
    this.setState({ borderRadius_canc: this.state.borderRadius });
    this.props.item.fontFamily = this.state.font_family;
    this.props.item.fontSize = this.state.font_size;
    this.props.item.fluidityZone = this.state.fluidityZone;
    this.props.item.restaurants = this.state.restaurants;
    this.props.item.restaurantSelected = this.state.restaurantSelected;
    this.props.item.zone = this.state.zone;

    this.props.item.fontFamilyHeader = this.state.font_familyHeader;
    this.props.item.fontSizeHeader = this.state.font_sizeHeader;
    this.props.item.colorHeader = this.state.colorHeader;

    this.props.item.fontFamilyTitle = this.state.font_familyTitle;
    this.props.item.fontSizeTitle = this.state.font_sizeTitle;
    this.props.item.colorTitle = this.state.colorTitle;
    this.props.item.colorDescription = this.state.colorDescription;
    this.props.item.borderRadius = this.state.Curved_tb;
    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };

  handleChangeRestaurant = (event) => {
    //Clear the drop down menu of the zone
    this.setState({ zones: [] });
    //Clear the list of zone in the component
    this.setState((prevState) => ({
      fluidityZone: prevState.fluidityZone.map((el) =>
      ({
        ...el, id_zone: "", text_zone: "",
      })
      ),
    }));

    this.setState({ restaurantSelected: event.target.value });

    getZonesByPlaceID(event.target.value).then((res) => {

      this.setState({ zones: res });
    });
  };

  //Change Text
  //Change Text zone 1
  handleChangeTextTitle_zone(event, keyId) {
    let key = keyId;

    this.setState((prevState) => ({
      fluidityZone: prevState.fluidityZone.map((el) =>
        el.key === key ? { ...el, zone_validation: event.target.value } : el
      ),
    }));

    this.setState((prevState) => ({
      fluidityZone: prevState.fluidityZone.map((el) =>
        el.key === key ? { ...el, text_zone: event.target.value } : el
      ),
    }));
  }

  // HANDLE ID ZONE
  handleChangeIDZone(event, keyId) {
    let key = keyId;
    this.setState((prevState) => ({
      fluidityZone: prevState.fluidityZone.map((el) =>
        el.key === key ? { ...el, id_zone: event.target.value } : el
      ),
    }));
  }

  //get ZONES Live
  getZoneLive(keyId) {
    let key = keyId;

    this.setState((prevState) => ({
      fluidityZone: prevState.fluidityZone.map((el) =>
        el.key === key ? { ...el, zone_valuelive: "" } : el
      ),
    }));

    getValueLive(this.state.fluidityZone[keyId].id_zone).then((res) => {
      this.setState((prevState) => ({
        fluidityZone: prevState.fluidityZone.map((el) =>
          el.key === key ? { ...el, zone_valuelive: res } : el
        ),
      }));
    });

    this.confirmZone(keyId);
  }

  confirmZone = (keyId) => {
    getRegionByID(this.state.fluidityZone[keyId].id_zone).then((res) => {
      this.setState((prevState) => ({
        fluidityZone: prevState.fluidityZone.map((el) =>
          el.key === keyId
            ? {
              ...el,
              border_color: "",
            }
            : el
        ),
      }));

      if (res[0]) {
        this.setState((prevState) => ({
          fluidityZone: prevState.fluidityZone.map((el) =>
            el.key === keyId ? { ...el, zone_validation: res[0].name } : el
          ),
        }));
        let capacity =
          (100 / res[0].max_capacity) *
          this.state.fluidityZone[keyId].zone_valuelive;

        if (capacity < res[0].fluidity_conf.fluidity_triggers[1].level) {
          this.setState((prevState) => ({
            fluidityZone: prevState.fluidityZone.map((el) =>
              el.key === keyId
                ? {
                  ...el,
                  border_color:
                    res[0].fluidity_conf.fluidity_triggers[0].color,
                  title: "Places disponibles",
                  description:
                    "il sera très facile de trouver une table libre",
                }
                : el
            ),
          }));
        } else if (capacity < res[0].fluidity_conf.fluidity_triggers[2].level) {
          this.setState((prevState) => ({
            fluidityZone: prevState.fluidityZone.map((el) =>
              el.key === keyId
                ? {
                  ...el,
                  border_color:
                    res[0].fluidity_conf.fluidity_triggers[1].color,
                  title: "Encore quelques places",
                  description:
                    "il sera plus compliqué de trouver une table libre",
                }
                : el
            ),
          }));
        } else if (capacity < 100) {
          this.setState((prevState) => ({
            fluidityZone: prevState.fluidityZone.map((el) =>
              el.key === keyId
                ? {
                  ...el,
                  border_color:
                    res[0].fluidity_conf.fluidity_triggers[2].color,
                  title: "C’est complet !",
                  description:
                    "il sera très difficile de trouver une table libre",
                }
                : el
            ),
          }));
        }
      } else {
        this.setState((prevState) => ({
          fluidityZone: prevState.fluidityZone.map((el) =>
            el.key === keyId
              ? {
                ...el,
                zone_validation: "l'iD zone don't exist",
                border_color: "#d6d6d6",
              }
              : el
          ),
        }));
      }
    });
  };
  borderRadiusContainer() {
    if (this.state.Curved_tb===undefined)
    {
      this.state.Curved_tb=0
     }
      let borderRadiusContainer = this.state.Curved_tb;
        return borderRadiusContainer;
  }
  render() {
    const divStyle = {
      margin: "0 4px",
      padding: "20px 8px",
      height: "100%",
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFFFFF",
      width: "100%",
      overflow: "hidden",
      borderRadius:this.borderRadiusContainer() +"px",
    };

    const IconStyle = {
      backgroundColor: this.state.colorHeader,
      width: "60px",
      height: "60px",
      marginBottom: "10px",
      marginTop: "15px",
      borderRadius: "100%",
      alignSelf: "center",
    };

    const headerStyle = {
      fontFamily: this.state.font_familyHeader,
      fontSize: this.state.font_sizeHeader + "px",
      color: this.state.colorHeader,
      marginBottom: "20px",
    };

    const squareStyle = {
      overflow: "hidden",
      margin: "4px",
      border: "5px solid #d6d6d6",
      height: "70px",
      width: "70px",
      borderRadius: "15%",
      backgroundColor: "#FFF",
      boxShadow: "rgb(0 0 0 / 57%) 0px 4px 5px",
    };
    const titleStyle = {
      margin: "0",
      fontWeight: "bold",
      fontFamily: this.state.font_familyTitle,
      fontSize: this.state.font_sizeTitle + "px",
      color: this.state.colorTitle,
    };
    const desctriptionStyle = {
      margin: "0",
      fontFamily: this.state.font_family,
      fontSize: this.state.font_size + "px",
      color: this.state.colorDescription,
    };

    return (
      <>
        <div className="container_component">
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div style={divStyle}>
            <div style={IconStyle} className="newshadow">
              <Image src={iconRestaurant} fluid />
            </div>
            <h2 className="text-center " style={headerStyle}>
              OÙ S’ASSEOIR POUR DÉJEUNER ?
            </h2>

            <div className="listcontainer-infoFluidity">
              {this.state.fluidityZone !== "" &&
                this.state.fluidityZone
                  .filter(function (value) {
                    return value.id_zone !== "";
                  })
                  .map(function (zone, i) {
                    return (
                      <div className="justify-content-md-center d-flex item-infoFluidity">
                        <Col md="auto">
                          <div
                            style={Object.assign(
                              { borderColor: zone.border_color },
                              squareStyle
                            )}
                          >
                            <h3 className="number-infoFluidity">
                              {zone.text_zone}
                            </h3>
                          </div>
                        </Col>
                        <Col xs className="textcontainer-infoFluidity">
                          <h3 style={titleStyle}>{zone.title}</h3>
                          <p style={desctriptionStyle}>{zone.description}.</p>
                        </Col>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Info Fluidity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* FONTFAMILY Header */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Header</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamilyHeader}
                          value={this.state.font_familyHeader}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT SIZE Header*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size Header</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.font_sizeHeader}
                          onChange={this.handleChangeFontSizeHeader}
                          min="30"
                          max="40"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.font_sizeHeader}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Color Text Header */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text Header</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.colorHeader}
                          onChange={this.handleChangeColorTextHeader}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.colorHeader}
                          onChange={this.handleChangeColorTextHeader}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.colorHeader}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONTFAMILY Title*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Title</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamilyTitle}
                          value={this.state.font_familyTitle}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT SIZE Title*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size Title</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.font_sizeTitle}
                          onChange={this.handleChangeFontSizeTitle}
                          min="20"
                          max="30"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.font_sizeTitle}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Color Text  Title*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text Title</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.colorTitle}
                          onChange={this.handleChangeColorTextTitle}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.colorTitle}
                          onChange={this.handleChangeColorTextTitle}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.colorTitle}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONTFAMILY Description*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Description</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamily}
                          value={this.state.font_family}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT SIZE Description*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size Description</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.font_size}
                          onChange={this.handleChangeFontSize}
                          min="13"
                          max="23"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.font_size}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Color Text  Description*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text Description</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.colorDescription}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.colorDescription}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.colorDescription}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/*Restaurant*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Restaurant Selection</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeRestaurant}
                          value={this.state.restaurantSelected}
                        >
                          <option value="">

                          </option>
                          {this.state.restaurants.map((restaurant) => {

                            return (
                              <option value={restaurant.id}>
                                {restaurant.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Form.Text className="text-muted">
                        {this.state.text}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Zone 1*/}
                <Row>
                  <Col md={12}>
                    <h5>1 Zone</h5>
                  </Col>
                  {/* Zone ID*/}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Zone ID</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.handleChangeIDZone(e, 0);
                            }}
                            value={this.state.fluidityZone[0].id_zone}
                          >
                            <option value=""></option>
                            {this.state.zones &&
                              this.state.zones.map((zona) => {
                                return (
                                  <option value={zona.id}>{zona.name}</option>
                                );
                              })}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          <h6>Place:{this.state.fluidityZone[0].id_zone}</h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* Text Title */}

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          maxLength="3"
                          value={this.state.fluidityZone[0].text_zone}
                          onChange={(e) => {
                            this.handleChangeTextTitle_zone(e, 0);
                          }}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Zone 2*/}

                <Row>
                  <Col md={12}>
                    <h5>2 Zone</h5>
                  </Col>
                  {/* Zone ID*/}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Zone ID</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.handleChangeIDZone(e, 1);
                            }}
                            value={this.state.fluidityZone[1].id_zone}
                          >
                            <option value=""> </option>
                            {this.state.zones &&
                              this.state.zones.map((zona) => {
                                return (
                                  <option value={zona.id}>{zona.name}</option>
                                );
                              })}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          <h6>Place:{this.state.fluidityZone[1].id_zone}</h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* Text Title */}

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          maxLength="3"
                          value={this.state.fluidityZone[1].text_zone}
                          onChange={(e) => {
                            this.handleChangeTextTitle_zone(e, 1);
                          }}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Zone 3*/}

                <Row>
                  <Col md={12}>
                    <h5>3 Zone</h5>
                  </Col>
                  {/* Zone ID*/}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Zone ID</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.handleChangeIDZone(e, 2);
                            }}
                            value={this.state.fluidityZone[2].id_zone}
                          >
                            <option value=""> </option>
                            {this.state.zones &&
                              this.state.zones.map((zona) => {
                                return (
                                  <option value={zona.id}>{zona.name}</option>
                                );
                              })}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          <h6>Place: {this.state.fluidityZone[2].id_zone}</h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* Text Title */}

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          maxLength="3"
                          value={this.state.fluidityZone[2].text_zone}
                          onChange={(e) => {
                            this.handleChangeTextTitle_zone(e, 2);
                          }}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Zone 4*/}

                <Row>
                  <Col md={12}>
                    <h5>4 Zone</h5>
                  </Col>

                  {/* Zone ID*/}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Zone ID</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.handleChangeIDZone(e, 3);
                            }}
                            value={this.state.fluidityZone[3].id_zone}
                          >
                            <option value=""> </option>
                            {this.state.zones &&
                              this.state.zones.map((zona) => {
                                return (
                                  <option value={zona.id}>{zona.name}</option>
                                );
                              })}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          <h6>Place:{this.state.fluidityZone[3].id_zone}</h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* Text Title */}

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          maxLength="3"
                          value={this.state.fluidityZone[3].text_zone}
                          onChange={(e) => {
                            this.handleChangeTextTitle_zone(e, 3);
                          }}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Zone 5*/}

                <Row>
                  <Col md={12}>
                    <h5>5 Zone</h5>
                  </Col>
                  {/* Zone ID*/}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Zone ID</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.handleChangeIDZone(e, 4);
                            }}
                            value={this.state.fluidityZone[4].id_zone}
                          >
                            <option value=""> </option>
                            {this.state.zones &&
                              this.state.zones.map((zona) => {
                                return (
                                  <option value={zona.id}>{zona.name}</option>
                                );
                              })}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          <h6>Place:{this.state.fluidityZone[4].id_zone}</h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* Text Title */}

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          maxLength="3"
                          value={this.state.fluidityZone[4].text_zone}
                          onChange={(e) => {
                            this.handleChangeTextTitle_zone(e, 4);
                          }}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Zone 6*/}

                <Row>
                  <Col md={12}>
                    <h5>6 Zone</h5>
                  </Col>
                  {/* Zone ID*/}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Zone ID</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.handleChangeIDZone(e, 5);
                            }}
                            value={this.state.fluidityZone[5].id_zone}
                          >
                            <option value=""> </option>
                            {this.state.zones &&
                              this.state.zones.map((zona) => {
                                return (
                                  <option value={zona.id}>{zona.name}</option>
                                );
                              })}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          <h6>Place:{this.state.fluidityZone[5].id_zone} </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* Text Title */}

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          maxLength="3"
                          value={this.state.fluidityZone[5].text_zone}
                          onChange={(e) => {
                            this.handleChangeTextTitle_zone(e, 5);
                          }}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Zone 7*/}

                <Row>
                  <Col md={12}>
                    <h5>7 Zone</h5>
                  </Col>
                  {/* Zone ID*/}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Zone ID</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.handleChangeIDZone(e, 6);
                            }}
                            value={this.state.fluidityZone[6].id_zone}
                          >
                            <option value=""> </option>
                            {this.state.zones &&
                              this.state.zones.map((zona) => {
                                return (
                                  <option value={zona.id}>{zona.name}</option>
                                );
                              })}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          <h6>Place:{this.state.fluidityZone[6].id_zone}</h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* Text Title */}

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          maxLength="3"
                          value={this.state.fluidityZone[6].text_zone}
                          onChange={(e) => {
                            this.handleChangeTextTitle_zone(e, 6);
                          }}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Zone 8*/}

                <Row>
                  <Col md={12}>
                    <h5>8 Zone</h5>
                  </Col>
                  {/* Zone ID*/}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Zone ID</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              this.handleChangeIDZone(e, 7);
                            }}
                            value={this.state.fluidityZone[7].id_zone}
                          >
                            <option value=""> </option>
                            {this.state.zones &&
                              this.state.zones.map((zona) => {
                                return (
                                  <option value={zona.id}>{zona.name}</option>
                                );
                              })}
                          </Form.Control>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          <h6>Place:{this.state.fluidityZone[7].id_zone}</h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* Text Title */}

                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={12}>
                        <Form.Control
                          type="text"
                          maxLength="3"
                          value={this.state.fluidityZone[7].text_zone}
                          onChange={(e) => {
                            this.handleChangeTextTitle_zone(e, 7);
                          }}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Z INDEX */}

                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                 {/* Curved Edges*/}
                 <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Curved Edges Pixels</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.Curved_tb}
                          onChange={this.handleChangeCurved}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          Curved Edges Pixels: {this.state.Curved_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Info fluidity</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default InfoFluidity;
