import React from "react";
import {
  Button,
  Modal,
  Form,
  Image,
  Col,
  Row,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import thumb from "../../image/noImage.png";
import { uploadFile, getFoodGroupByID } from "../../api/Api";
import { getFileExtension } from "../../utils/Utils";
import FontList from "../../styles/FontList";

const listFont = FontList;

class TitleBar extends React.Component {
  constructor(props) {
    super(props);
    this.titleBar = React.createRef();
    this.state = {
      show_delete: false,
      show_modify: false,
      zindex_tb: this.props.item.zIndex,
      zindex_tb_canc: this.props.item.zIndex,
      color_bgicon: this.props.item.backgroundIcon,
      color_bgicon_canc: this.props.item.backgroundIcon,
      color_icon: this.props.item.color,
      color_icon_canc: this.props.item.color,
      color_bg: this.props.item.backgroundText,
      color_bg_canc: this.props.item.backgroundText,
      color_text: this.props.item.textColor,
      color_text_canc: this.props.item.textColor,
      image: this.props.item.icon,
      image_canc: this.props.item.icon,
      text: this.props.item.text,
      text_canc: this.props.item.text,
      fontfamily: this.props.item.textFont,
      fontfamily_canc: this.props.item.textFont,
      fontsize: this.props.item.textSize,
      fontsize_canc: this.props.item.textSize,
      imageFile: new File([], ""),
      disabled: false,
      previewImage: thumb,
      id_foodgroup: this.props.id,
      foodgroup_name: "",
      foodgroup_validation: "",
      disabledFromId: "true",
      Curved_tb: this.props.item.borderRadius,
      Curved_tb_canc: this.props.item.borderRadius,
    };

    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleChangeColorBgIcon = this.handleChangeColorBgIcon.bind(this);
    this.handleChangeColorBg = this.handleChangeColorBg.bind(this);
    this.handleChangeColorText = this.handleChangeColorText.bind(this);
    this.handleChangeColorIcon = this.handleChangeColorIcon.bind(this);
    this.handleChangeTextTitle = this.handleChangeTextTitle.bind(this);
    this.handleChangeFontSize = this.handleChangeFontSize.bind(this);
    this.handleChangeFontFamily = this.handleChangeFontFamily.bind(this);
    this.handleChangeIDFoodgroup = this.handleChangeIDFoodgroup.bind(this);
    //this.handleSwitchInputCurved = this.handleSwitchInputCurved.bind(this);
    this.handleChangeCurved = this.handleChangeCurved.bind(this);

  }
  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };


  //handleswitchInput
  handleswitchInput = (event) => {
    this.setState({ disabledFromId: event.target.checked });
    if (this.state.disabledFromId === true) {
      this.setState({ id_foodgroup: "" });
      this.setState({ text: "" });
      this.setState({ image: "" });
    } else {
      this.setState({ id_foodgroup: "" });
      this.setState({ text: "" });
      this.setState({ image: "" });
    }
  };

  //Change Color Background Bar
  handleChangeColorBg(event) {
    this.setState({ color_bg: event.target.value });
  }
  //Change Color background Icon
  handleChangeColorBgIcon(event) {
    this.setState({ color_bgicon: event.target.value });
  }

  //Change Color  Icon
  handleChangeColorIcon(event) {
    this.setState({ color_icon: event.target.value });
  }
  //Change Color Text
  handleChangeColorText(event) {
    this.setState({ color_text: event.target.value });
  }
  //Change Text
  handleChangeTextTitle(event) {
    if (event.target.value.length > 20) {
      this.setState({ text: event.target.value.substring(0, 20) });
    } else {
      this.setState({ text: event.target.value });
    }
  }
  //Change Font Family
  handleChangeFontFamily(event) {
    this.setState({ fontfamily: event.target.value });
  }
    //Change Curved
   handleChangeCurved(event) {
          this.setState({ Curved_tb: event.target.value });

    }
  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex_tb: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex_tb: 1 });
    } else {
      this.setState({ zindex_tb: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Change Icon
  handleChangeImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ disabled: true });
        this.setState({ image: reader.result });
        this.setState({ previewImage: reader.result });
        this.uploadImage();
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    this.setState({ imageFile: event.target.files[0] });
  }

  handleDeleteImage = () => {
    this.setState({ image: "" });
  };
  handleChangeFontSize(event) {
    if (event.target.value > 62) {
      this.setState({ fontsize: 62 });
    } else if (event.target.value < 40) {
      this.setState({ fontsize: 40 });
    } else {
      this.setState({ fontsize: event.target.value });
    }
  }
  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ color_bgicon: this.state.color_bgicon_canc });
    this.setState({ color_icon: this.state.color_icon_canc });
    this.setState({ color_text: this.state.color_text_canc });
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ text: this.state.text_canc });
    this.setState({ zindex_tb: this.state.zindex_tb_canc });
    this.setState({ image: this.state.image_canc });
    this.setState({ fontfamily: this.state.fontfamily_canc });
    this.setState({ Curved_tb: this.state.Curved_tb_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_tb_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ color_bgicon_canc: this.state.color_bgicon });
    this.setState({ color_icon_canc: this.state.color_icon });
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ color_text_canc: this.state.color_text });
    this.setState({ text_canc: this.state.text });
    this.setState({ zindex_tb_canc: this.state.zindex_tb });
    this.setState({ Curved_tb_canc: this.state.Curved_tb });
    this.setState({ image_canc: this.state.image });
    this.setState({ fontfamily_canc: this.state.fontfamily });
    this.setState({ id_foodgroup_canc: this.state.id_foodgroup });
    this.setState({ borderRadius_canc: this.state.borderRadius });
    this.props.item.backgroundIcon = this.state.color_bgicon;
    this.props.item.color = this.state.color_icon;
    this.props.item.backgroundText = this.state.color_bg;
    this.props.item.icon = this.state.image;
    this.props.item.text = this.state.text;
    this.props.item.textSize = this.state.fontsize;
    this.props.item.textColor = this.state.color_text;
    this.props.item.textFont = this.state.fontfamily;
    this.props.item.id = this.state.id_foodgroup;
    this.props.item.borderRadius = this.state.Curved_tb;
    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };

  //UPLOAD IMAGE
  uploadImage = () => {
    if (getFileExtension(this.state.imageFile.name) === "svg") {
      this.state.imageFile
        .text()
        .then((response) => {
          let str = response.replace(
            /<style\b[^<>]*>[\s\S]*?<\/style\s*>/gi,
            ""
          );
          this.setState({ image: str });
        })
        .then(() => {
          this.props.item.backgroundImage =
            this.state.image && this.setState({ disabled: false });
        });
    } else {
      let obj = {};
      uploadFile(this.state.imageFile)
        .then((response) => {
          this.setState({ image: response.location });
        })
        .then(() => {
          this.props.item.backgroundImage =
            this.state.image && this.setState({ disabled: false });
        });
    }
  };

  //Handle ID FOODGROUP
  handleChangeIDFoodgroup(event) {
    this.setState({ id_foodgroup: event.target.value });
  }

  //get FOOD Item FROM FOOD GROUPID
  getFoodItems = () => {
    this.setState({ foodgroup_validation: "" });
    this.setState({ text: "" });
    this.setState({ image: "" });
    getFoodGroupByID(this.state.id_foodgroup).then((res) => {
      this.setState({ foodgroup_validation: "" });
      if (res == null) {
        //  this.setState({ foodgroup_name: "" });
        this.setState({ foodgroup_validation: "Food Group empty" });
      } else if (res) {
        this.setState({ foodgroup_validation: res.name });

        this.setState({ text: res.name });
        this.svgRender(res.picto);
      } else {
        //  this.setState({ text: "" });
        this.setState({ foodgroup_validation: "l'iD food group don't exist" });
      }
    });
  };

  svgRender(svgPicture) {
    if (svgPicture) {
      let svgClear = svgPicture.replace(/\\/g, "").replace("<style>", "");
      this.setState({ image: svgClear });
    } else {
      this.setState({ image: "" });
    }
  }
  widthIconContainer() {
    if (this.titleBar.current) {
      let heightIconContainer = this.titleBar.current.offsetHeight;
      return heightIconContainer;
    }
  }
  borderRadiusContainer() {
    if (this.state.Curved_tb===undefined)
    {
      this.state.Curved_tb=0
     }
      let borderRadiusContainer = this.state.Curved_tb;
        return borderRadiusContainer;
  }
  render() {
     const divStyle = {
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      overflow: "hidden",
      borderRadius:this.borderRadiusContainer() +"px",
    };

    const iconContainer = {
      padding: "8px",
      minWidth: this.widthIconContainer() + "px",
      backgroundColor: this.state.color_bgicon,
      fill: this.state.color_icon,
    };

    const titleStyle = {
      width: "max-content",
      minWidth: "100%",
      color: this.state.color_text,
      backgroundColor: this.state.color_bg,
      fontSize: this.state.fontsize + "px",
      display: "block",
      justifyContent: "center",
      fontFamily: this.state.fontfamily,
    };

    const thumbContainer = {
      backgroundColor: this.state.color_bgicon,
      fill: this.state.color_icon,
    };
    const imageVector = {
      height: "100%",
      display: "flex",
      alignContent: "center",
    };

    const imageRaw = {
      width: "100%",
      objectFit: "contain",
      height: "100%",
    };

    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator

    return (
      <>
        <div
          className="container_component "
          style={divStyle}
          ref={this.titleBar}
        >
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div className="icon_container resizeSVG" style={iconContainer}>
            {!pattern.test(this.state.image) && (
              <div
                dangerouslySetInnerHTML={{ __html: this.state.image }}
                style={imageVector}
              />
            )}
            {!!pattern.test(this.state.image) && (
              <img
                src={this.state.image}
                width="80"
                height="80"
                style={imageRaw}
                alt=""
              />
            )}
          </div>
          <div className="text_title" style={titleStyle}>
            <h1>{this.state.text}</h1>
          </div>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Title Bar1</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  {/* FOODGROUP ID  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <Form.Check
                          checked={this.state.disabledFromId}
                          onChange={this.handleswitchInput}
                          type="switch"
                          id="custom-switch"
                          label="ID/Input "
                        />
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.id_foodgroup}
                            onChange={this.handleChangeIDFoodgroup}
                            disabled={!this.state.disabledFromId}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems}
                              disabled={!this.state.disabledFromId}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.foodgroup_validation ===
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Image ICON */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Icon</Form.Label>
                      <Col md={6}>
                        <div style={thumbContainer} className="resizeSVG">
                          {!pattern.test(this.state.image) && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.image,
                              }}
                            />
                          )}
                          {!!pattern.test(this.state.image) && (
                            <img src={this.state.image} width="100%" />
                          )}
                          {this.state.image === "" && (
                            <Image src={thumb} thumbnail />
                          )}

                          {this.state.image !== "" && (
                            <Button
                              className="circlebutton"
                              variant="danger"
                              onClick={this.handleDeleteImage}
                              disabled={this.state.disabledFromId}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                          )}
                        </div>
                        <Form.File
                          accept="image/*"
                          className={"mt-2"}
                          onChange={this.handleChangeImage}
                          disabled={this.state.disabledFromId}
                        />

                        {this.state.disabled === true && (
                          <p>Loading Image...</p>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Text Title */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Text Title</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          type="text"
                          value={this.state.text}
                          onChange={this.handleChangeTextTitle}
                          disabled={this.state.disabledFromId}
                        />

                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* BACKGROUND ICON */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color background Icon</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_bgicon}
                          onChange={this.handleChangeColorBgIcon}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bgicon}
                          onChange={this.handleChangeColorBgIcon}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bgicon}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color ICON */}

                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Icon</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_icon}
                          onChange={this.handleChangeColorIcon}
                          disabled={
                            pattern.test(this.state.image) ||
                            this.state.image === ""
                              ? true
                              : false
                          }
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_icon}
                          onChange={this.handleChangeColorIcon}
                          disabled={
                            pattern.test(this.state.image) ||
                            this.state.image === ""
                              ? true
                              : false
                          }
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_icon}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Background color Title */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Bar text</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color Title */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT SIZE TITLE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontsize}
                          onChange={this.handleChangeFontSize}
                          min="40"
                          max="63"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontsize}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamily}
                          value={this.state.fontfamily}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Z-index*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex_tb}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                  {/* Curved Edges*/}
                  <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Curved Edges Pixels</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.Curved_tb}
                          onChange={this.handleChangeCurved}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          Curved Edges Pixels: {this.state.Curved_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={this.cancelOnModal}
                disabled={this.state.disabled}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={this.confirmChangeonModal}
                disabled={this.state.disabled}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Title Bar</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default TitleBar;
