import React from "react";
import { Button, Modal, Form, Image, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import thumb from "../../image/noImage.png";
import { uploadFile } from "../../api/Api";

class Background extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      color_bg: this.props.item.backgroundColor,
      color_bg_canc: this.props.item.backgroundColor,
      zindex_bg: this.props.item.zIndex,
      zindex_bg_canc: this.props.item.zIndex,
      image: this.props.item.backgroundImage,
      image_canc: this.props.item.backgroundImage,
      imageFile: new File([], ""),
      disabled: false,
      Curved_tb: this.props.item.borderRadius,
      Curved_tb_canc: this.props.item.borderRadius,
    };
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleChangeCurved = this.handleChangeCurved.bind(this);
  }
  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };
  //Change Color
  handleChangeColor(event) {
    this.setState({ color_bg: event.target.value });
  }
    //Change Curved
    handleChangeCurved(event) {
      this.setState({ Curved_tb: event.target.value });

}
  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex_bg: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex_bg: 1 });
    } else {
      this.setState({ zindex_bg: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Change Image
  handleChangeImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ disabled: true });
        this.setState({ image: reader.result });
        this.uploadImage();
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    this.setState({ imageFile: event.target.files[0] });
  }

  handleDeleteImage = () => {
    this.setState({ image: "" });
  };

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ zindex_bg: this.state.zindex_bg_canc });
    this.setState({ image: this.state.image_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_bg_canc);
    this.setState({ Curved_tb: this.state.Curved_tb_canc });
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ zindex_bg_canc: this.state.zindex_bg });
    this.setState({ image_canc: this.state.image });
    this.setState({ borderRadius_canc: this.state.borderRadius });
    this.props.item.backgroundColor = this.state.color_bg;
    this.props.item.backgroundImage = this.state.image;
    this.props.item.borderRadius = this.state.Curved_tb;
    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };

  //UPLOAD IMAGE
  uploadImage = () => {
    let obj = {};
    uploadFile(this.state.imageFile)
      .then((response) => {
        this.setState({ image: response.location });
      })
      .then(() => {
        this.props.item.backgroundImage =
          this.state.image && this.setState({ disabled: false });
      });
  };
  borderRadiusContainer() {
    if (this.state.Curved_tb===undefined)
    {
      this.state.Curved_tb=0
     }
      let borderRadiusContainer = this.state.Curved_tb;
        return borderRadiusContainer;
  }
  render() {
    const divStyle = {
      backgroundColor: this.state.color_bg,
      width: "100%",
      height: "100%",
      transition: "all 250ms ease",
      backgroundImage: `url(${this.state.image})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderRadius:this.borderRadiusContainer() +"px",
    };

    return (
      <>
        <div style={divStyle}>
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
          fade={false}
          animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Background</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColor}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColor}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>BackgroundImage</Form.Label>
                      <Col md={6}>
                        <Image
                          src={
                            this.state.image === "" ? thumb : this.state.image
                          }
                          thumbnail
                        />
                        {this.state.image !== "" && (
                          <Button
                            className="circlebutton"
                            variant="danger"
                            onClick={this.handleDeleteImage}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        )}
                        <Form.File
                          accept="image/png, image/jpeg"
                          className={"mt-2"}
                          onChange={this.handleChangeImage}
                        />

                        {this.state.disabled === true && (
                          <p>Loading Image...</p>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex_bg}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                               {/* Curved Edges*/}
                               <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Curved Edges Pixels</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.Curved_tb}
                          onChange={this.handleChangeCurved}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          Curved Edges Pixels: {this.state.Curved_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={this.cancelOnModal}
                disabled={this.state.disabled}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={this.confirmChangeonModal}
                disabled={this.state.disabled}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete background</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default Background;
