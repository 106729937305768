import React from "react";
import { Button, Modal, Form, Carousel, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getPromotionTags } from "../../api/Api";

// import thumb from "../../image/noImage.png";
// import video from "../../image/Nature Beautiful short video 720p HD.mp4";

class PromoVertical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      zindex_bg: this.props.item.zIndex,
      zindex_bg_canc: this.props.item.zIndex,
      promoTag: [],
      tagSelected: this.props.item.tag,
      actualWidth: 0,
      Curved_tb: this.props.item.borderRadius,
      Curved_tb_canc: this.props.item.borderRadius,
    };
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeSelectTag = this.handleChangeSelectTag.bind(this);
    this.handleChangeCurved = this.handleChangeCurved.bind(this);
  }

  componentDidMount() {
    this.getTag();
    this.setWidth();
  }

  setWidth() {
    this.props.layout.forEach((element) => {
      if (element.i === this.props.item.i) {
        this.setState({ actualWidth: element.w });
      }
    });
  }

  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
    this.setWidth();
  };
    //Change Curved
 handleChangeCurved(event) {
      this.setState({ Curved_tb: event.target.value });

  }
  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex_bg: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex_bg: 1 });
    } else {
      this.setState({ zindex_bg: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ zindex_bg: this.state.zindex_bg_canc });
    this.setState({ Curved_tb: this.state.Curved_tb_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_bg_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ zindex_bg_canc: this.state.zindex_bg });
    this.setState({ borderRadius_canc: this.state.borderRadius });
    this.props.item.borderRadius = this.state.Curved_tb;
    this.props.item.tag = this.state.tagSelected;
    // this.props.onStyleChange(this.props.item)
    this.handleCloseModify();
  };

  //Change Promo Tag
  handleChangeSelectTag(event) {
    this.setState({ tagSelected: event.target.value });
  }

  //GET TAG
  getTag = () => {
    getPromotionTags().then((res) => {
      if (res[0]) {
        this.setState({ promoTag: res });
        //  this.setState({ tagSelected: this.state.promoTag[0].name});
      }
    });
  };
  borderRadiusContainer() {
    if (this.state.Curved_tb===undefined)
    {
      this.state.Curved_tb=0
     }
      let borderRadiusContainer = this.state.Curved_tb;
        return borderRadiusContainer;
  }
  render() {
    const divStyle = {
      borderRadius:this.borderRadiusContainer() +"px",
    };
    return (
      <>
        <div style={divStyle} className="promo">
          <Carousel>
            <Carousel.Item>
              <div className="slide-demo">
                <h2>#{this.state.tagSelected}</h2>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide-demo">
                <h2>#{this.state.tagSelected}</h2>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="slide-demo">
                <h2>#{this.state.tagSelected}</h2>
              </div>
            </Carousel.Item>
            {/*  <Carousel.Item>
               <div className="video-section">
                <ResponsiveEmbed aspectRatio="16by9">
                  <embed src={video} autoplay />
                </ResponsiveEmbed>
              </div> 
            </Carousel.Item>*/}
          </Carousel>
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Promotion</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Tag Promotion</Form.Label>

                      <Form.Control
                        as="select"
                        onChange={this.handleChangeSelectTag}
                        value={this.state.tagSelected}
                      >
                        {this.state.promoTag
                          .filter(function (value) {
                            return value.name !== "All";
                          })
                          .map((tag) => {
                            return <option value={tag.name}>{tag.name}</option>;
                          })}
                      </Form.Control>
                      <Form.Text className="text-muted">
                        {this.state.text}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex_bg}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Curved Edges*/}
                <Row>
                 <Col md={10}>
                  <Form.Group>
                      <Form.Label>Curved Edges Pixels</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.Curved_tb}
                          onChange={this.handleChangeCurved}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          Curved Edges Pixels: {this.state.Curved_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Promotion</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default PromoVertical;
